import LayoutC from 'components/layouts/layout-c';
import React, { useCallback, useState } from 'react';
import { Field, Input, Button, Text, Title, ButtonLink } from 'components/commons';
import lang from 'translations';
import { initialState } from './forgot.state';
import { useApi, useForm } from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { forgotPassword } from 'apis/profile.api';

const ForgotPassword = () => {
  const location = useLocation();

  const history = useHistory();

  const { fields, modifyField, applyFieldErrors } = useForm({
    initialState,
  });

  const [delivered, setDelivered] = useState(false);

  const { request, loading } = useApi({
    api: forgotPassword,
    handleOwnError: true,
  });

  const handleSendResetLink = useCallback(async () => {
    try {
      const result = await request({ email: fields.email.value });
      setDelivered(result);
    } catch ({ handleError, code }) {
      const error = {
        2001: () => {
          applyFieldErrors({
            email: lang.emailNotMatchAccount,
          });
        },
      };
      if (error[code]) {
        return error[code]();
      }
      setDelivered(undefined);
      return handleError();
    }
    //eslint-disable-next-line
  }, [fields.email]);

  // const showError = () => {
  //   Toast({
  //     content: lang.somethingWentWrong,
  //     error: true,
  //     icon: 'exclamation-fill',
  //   }).open();
  // };

  const backToLogin = useCallback(async () => {
    history.push(`/auth${location.search}`);
  }, [location, history]);

  return (
    <LayoutC
      leftChild={
        <div>
          <Title xl>{delivered ? lang.resetPasswordLinkSent : lang.forgotPassword}</Title>
          {delivered ? (
            <div className="flex">
              <Text>
                {lang.pleaseCheck}
                <span className="font-bold text-sm text-black break-word venue-text">{`${fields.email.value}`}</span>
                {''}
                {lang.andClickResetPassword}
              </Text>
            </div>
          ) : (
            <Text>{lang.enterEmailToReset}</Text>
          )}
          {!delivered && (
            <div className="py-lg">
              <Field
                {...fields.email}
                customLabel={
                  <Text size="text-xs" color="text-gray-500 font-semibold">
                    {lang.emailAddress}
                  </Text>
                }
              >
                <Input {...fields.email} onChange={modifyField}></Input>
              </Field>
            </div>
          )}
          <Button
            className="mt-md"
            onClick={delivered ? backToLogin : handleSendResetLink}
            disabled={fields.email.value === '' || fields.email.error || loading}
            loading={loading}
          >
            {delivered ? lang.backToLogin : lang.sendResetLink}
          </Button>
          <div className="mt-md flex">
            {delivered && <Text>{lang.didNotReceiveAnyThing}</Text>}
            <ButtonLink className="ml-1" onClick={delivered ? handleSendResetLink : backToLogin}>
              {delivered ? lang.resendResetLink : lang.backToLogin}
            </ButtonLink>
          </div>
        </div>
      }
    ></LayoutC>
  );
};

export default ForgotPassword;
