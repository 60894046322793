export const environment = {
  id: 'dev',
  production: false,
  API_V3: 'https://dev.gw.pouchnation.com',
  API_COGNITO: 'https://dev.api.pouchnation.com',
  FIREBASE: {
    apiKey: 'AIzaSyA8MHAKq7U5WXKCI2yLxV8vldfDDbJAgHs',
    authDomain: 'pouch-venue-c587f.firebaseapp.com',
    databaseURL: 'https://pouch-venue-c587f-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'pouch-venue-c587f',
    storageBucket: 'pouch-venue-c587f.appspot.com',
    messagingSenderId: '625155685277',
    appId: '1:625155685277:web:937441508d5ca5b9608d74',
    measurementId: 'G-0YZ202H7JL',
  },
  MIXPANEL_TOKEN: 'dd2b9cdaccee496cd63dd45ac4f09f57',
  FEATURE_FLAG: {
    clientSideID: '5f7ad1ef2229060b440fd8bb',
  },
  SENTRY_DSN: 'https://0f17215f2e754bdb9688618dde61881f@o431184.ingest.sentry.io/6152555',
  ACTIVE_MQ_HOST:
    'wss://b-72cc9200-234b-44da-b219-54d0578327ff-1.mq.ap-southeast-1.amazonaws.com:61619',
  ACTIVE_MQ_USER: 'pndev',
  ACTIVE_MQ_PW: 'pouchnationdev123',
  VENUE_URL: 'https://dev.venue.pouchnation.com',
  VENUE_PAGE: 'https://dev.venue.pouchnation.com/redirect',
  POUCH_CONNECT: 'https://dev.accounts.pouchnation.com/',
  POUCH_CLIENT_ID: 'VENUE_DASHBOARD',
  POUCH_CLIENT_TYPE: 'email',
  AWS: {
    userPoolId: 'ap-southeast-1_dmnG41UsW',
    oauthDomain: 'dev-auth-connector-pouchnation-com.auth.ap-southeast-1.amazoncognito.com',
    userPoolWebClientId: '5htbcslsn12022t6s8k50kgg4k',
    redirectSignIn: 'https://dev.accounts.pouchnation.com/auth',
    redirectSignOut: 'https://dev.accounts.pouchnation.com/auth',
  },
  PABBLY_PIPEDRIVE_WEBHOOK: 'https://connect.pabbly.com/workflow/sendwebhookdata/IjE3ODg4OSI_3D',
};
